import { inject } from "mobx-react";
import { useLocation } from "react-router-dom";

import { SitesMenu } from "../../chrome/Navigation.component";
import type {
  SitesTemplateInfernoMicrositeNavigation,
  SitesTemplateInfernoMicrositeSocial,
} from "@ihr-radioedit/inferno-webapi";
import { resolveMenu } from "../../lib/menu";
import { matchPathByRoutes } from "@inferno/renderer-shared-core";
import { MagicLink } from "../../ui";
import styles from "./MicrositeMenu.style.module.scss";
import type { Store } from "@inferno/renderer-shared-core";

interface MicrositeMenuProps {
  navigation?: SitesTemplateInfernoMicrositeNavigation | null;
  social?: SitesTemplateInfernoMicrositeSocial;
  store?: Store;
}

export const MicrositeMenu = inject("store")(({ navigation, store }: MicrositeMenuProps) => {
  if (!store || !store.microsite || !navigation || !navigation.menu || !navigation.enable_nav_switch) {
    return null;
  }

  const { microsite } = store;
  const { pathname: fullpath } = useLocation();
  const pathname = fullpath.replace(`/featured/${microsite.index!.slug}`, "");

  const routePair = matchPathByRoutes(microsite.config.routes, pathname);

  let menu: SitesMenu[] = navigation.enable_nav_switch
    ? navigation.menu
        .filter(Boolean)
        .map(m => resolveMenu({ ...m }, store.env.MICROSITE_ROOT, routePair, microsite, {}))
    : [];

  // Filter out possible podcast menu items if the microsite lacks a default podcast, ref: IHRAL-8566
  if (!microsite.config.sections?.general?.default_podcasts?.length) {
    menu = menu.filter(m => m.label?.toLowerCase() !== "podcasts");
  }

  if (menu.length) {
    return (
      <nav className={styles.componentMicrositeMenu}>
        <ul className="menu-items">
          {menu.map((menuItem, i) => (
            <li key={i}>
              <MagicLink className={menuItem.active} to={menuItem.href || "#"} target={menuItem.target || "_top"}>
                {menuItem.label}
              </MagicLink>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
  return null;
});

export default MicrositeMenu;
